/* Container for the search bar */
.search-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
}

/* Search bar focus effect */
.search-bar-container input:focus {
    outline: 2px solid #0066cc;
    box-shadow: 0 4px 8px rgba(0, 102, 204, 0.4);
}

/* Hover effect for input */
.search-bar-container input:hover {
    box-shadow: 0 4px 8px rgba(0, 102, 204, 0.2);
}

/* Popover content styles */
.popover-content {
    padding: 8px;
    max-height: 300px;
    overflow-y: auto;
}

/* Individual popover item */
.popover-item {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.2s;
}

.popover-item:last-child {
    border-bottom: none;
}

.popover-item:hover {
    background-color: #f9f9f9;
}
