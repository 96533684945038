.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: red;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}
.navbar {
  border: none; /* Remove any borders */
  box-shadow: none; /* Remove shadow if it exists */
}



.content-container {
  display: flex;
  flex-direction: column; /* Stack heading and search bar vertically */
  align-items: center; /* Center children horizontally */
  margin-top: 150px; /* Adjust this to move both elements down together */
}

h1 {
  margin-bottom: 16px; /* Add space between the heading and search bar */
}

/* Center the modal on the screen */
.MuiModal-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Set the width of the Card component (the modal content) */
.MuiCard-root {
  width: 800px; /* Adjust width as necessary */
  max-width: 90%; /* Ensures it is responsive and doesn't overflow */
  padding: 20px;
}


/* Global padding/margin reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
