/* NavBar.css */

.MuiAppBar-root {
  background-color: transparent !important; /* Match gradient's top color */
  box-shadow: none !important; /* Remove shadow for a smoother look */
  padding: 8px;
}

.MuiButton-root {
  color: black !important; /* Set button text color to black */
  text-transform: none !important; /* Prevent buttons from displaying text in all caps */
}

.create-course-button {
  background-color: #003071 !important; /* Background hex color */
  color: white !important; /* Ensure text color is white */
  border-radius: 100px !important; /* Rounded corners */
  padding: 24px 20px;
  margin-left: 12px !important; /* Add margin-left to separate it from the previous buttons */
  min-width: 96px !important;
}

.logout-button {
  background-color: #546de7 !important; /* Red background for logout */
  color: white !important; /* White text */
  border-radius: 100px !important; /* Rounded corners */
  margin-left: 12px !important; /* Add space between buttons */
  padding: 24px 20px; /* Adjust padding for consistency */
  min-width: 96px !important;
}

/* General nav-button styles */
.nav-button {

  color: #1976d2; /* Eye-catching blue color */
  font-weight: bold; /* Make the text bold for emphasis */
  font-size: 30px;
  text-transform: uppercase; /* Capitalize the button text */
  letter-spacing: 5px; /* Add some spacing between letters */
  transition: all 0.3s ease; /* Smooth transitions for hover effects */
  cursor: pointer; /* Indicate clickable action */
}

.nav-button:hover {
  color: #ffffff; /* Change text color on hover */
  background: #1976d2; /* Solid color for hover effect */
  border-color: #0d47a1; /* Darker blue border on hover */
}

.nav-button:active {
  transform: scale(0.98); /* Slight press-down effect */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow reduction */
}

.nav-button.active {
  color: #6f00ff; /* Highlight color */
  font-weight: bold; /* Make text bold */
}

.nav-item {
  font-size: 1.0rem; /* Adjust size to resemble a heading */
  font-weight: 600;
  cursor: pointer;
  color: #555; /* Default color */
  margin: 0;
  padding: 0 5px;
  text-transform: capitalize;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.nav-item:hover {
  color: #1976d2;
}

.nav-item.active {
  color: #1976d2;
  font-weight: bold;
}
